<script setup lang="ts">
const inputEl = ref<HTMLInputElement>()

const payload = reactive({
    waitlist_id: 12923,
    email: '',
})
const isButtonDisabled = computed(() => !payload.email)

function onElementClick() {
    inputEl.value?.focus()
}

const log = useLog()
const emailError = ref<false | string>(false)
const isSigningUpWaitlist = ref(false)
const didSignupWaitlist = ref(false)

const resolvedLabel = computed(() => {
    if (emailError.value)
        return emailError.value

    return 'Enter your company email'
})

async function onSignup() {
    // Validate email
    if (!payload.email) {
        emailError.value = 'Please enter your email'
        return
    }

    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(payload.email)) {
        emailError.value = false
    }
    else {
        emailError.value = 'Please enter a valid email'
        return
    }

    isSigningUpWaitlist.value = true

    try {
        log.info('Signing up for waitlist', payload)
        await $fetch('https://api.getwaitlist.com/api/v1/waiter', {
            method: 'POST',
            body: payload,
        })
        didSignupWaitlist.value = true
    }
    catch (e) {
        log.error('Error signing the waitlist', payload)
    }

    isSigningUpWaitlist.value = false
}
</script>

<template>
    <div
        w-320px pt-10px pb-9px px-16px
        rounded-8px
        b=".5px white/12" bg="white/4"
        flex items-center cursor-text
        hover="bg-white/8" transition ease
        :class="[
            emailError && '!bg-#EF4343/16 !b-#EF4343/60',
            didSignupWaitlist && '!bg-white/0 b-white/16',
            isSigningUpWaitlist || didSignupWaitlist && 'bg-white/0 b-white/16',
        ]"
        @click="onElementClick"
    >
        <div grow>
            <div
                text-white-600 body-11-regular
                :class="[
                    emailError && '!text-negative',
                ]"
            >
                {{ resolvedLabel }}
            </div>
            <input
                ref="inputEl"
                v-model="payload.email"
                type="email"
                body-14-regular bg-transparent
                b-0 placeholder:text-black-700
                placeholder="Write here"
                text-white
                :disabled="isSigningUpWaitlist || didSignupWaitlist"
                @keyup.enter="onSignup"
            >
        </div>
        <button
            body-12-semibold
            bg-white
            py-8px px-14px rounded-38px
            text-black-900
            transition ease-out
            :class="[
                isButtonDisabled && 'bg-white/8 text-white-400',
                (didSignupWaitlist || isSigningUpWaitlist) && '!bg-white/8',
            ]"
            :disabled="didSignupWaitlist"
            @click.stop="onSignup"
        >
            <div v-if="didSignupWaitlist" i-ph-check text-white />
            <div v-else-if="isSigningUpWaitlist" i-ph-circle-notch animate-spin text-white />
            <span v-else class="!text-black-900">Send</span>
        </button>
    </div>
</template>
