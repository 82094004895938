<template>
    <div class="gradient" />
</template>

<style scoped>
.gradient {
    --dot-size: 8px;

    z-index: 0;
    background: radial-gradient(47.96% 79.38% at 53.29% 45.54%, #303E79 31%, #020913 100%);
    mask-size: var(--dot-size) var(--dot-size);
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 10 10'%3E%3Ccircle cx='1' cy='1' r='1'/%3E%3C/svg%3E");
    mask-repeat: repeat;
}
</style>
